import React from "react";

const HeaderShadowMobile = (): JSX.Element => {
  return (
    // top-[80px] is related to the height of HeaderMenuMobile height
    <div className="fixed top-[72px] -z-10 h-[20.15%] w-full bg-gradient-to-b from-[#16181A] opacity-[0.03] desktop:hidden" />
  );
};

export default HeaderShadowMobile;
